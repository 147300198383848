<template>
    <div class="baseMain">
        <div class="frontPage">
            <div class="frontPageIn">
                <div class="frontPageTop">
                    <span class="frontPageTopText">昨日数据概览</span>
                </div>
                <div class="frontPageTop">
                    <span class="frontPageTopText2">数据计算可能略有延时，最晚于次日10时更新前一天数据</span>
                </div>
                <div class="frontPageEchart">
                    <div class="frontPageEchartTop">
                        <div class="frontPageEchartTopOne"
                             :class="{'frontPageEchartTopAc':topListDataSel == index,'frontPageEchartTopBL':index>0}"
                             v-for="(item,index) in topListData" :key="index" @click="selOneTab(index)">
                            <div class="frontPageEchartTopText">
                                <span>{{item.title}}</span>
                            </div>
                            <div class="frontPageEchartTopText2">
                                <span>{{item.money}}</span>
                            </div>
                            <div class="frontPageEchartTopText3">
                                <span>对比上24小时</span>
                                &nbsp;
                                <template v-if="item.sort == 'up'">
                                    <el-icon size="12px" class="frontPageEchartTopText5">
                                        <top/>
                                    </el-icon>
                                    <span class="frontPageEchartTopText5">{{item.baifenbi}}%</span>
                                </template>
                                <template v-else>
                                    <el-icon size="12px" class="frontPageEchartTopText4">
                                        <bottom/>
                                    </el-icon>
                                    <span class="frontPageEchartTopText4">{{item.baifenbi}}%</span>
                                </template>
                            </div>
                            <div class="frontPageEchartTopOneSel" v-if="topListDataSel == index">
                                <el-icon size="30px" color="#009b40">
                                    <CaretBottom/>
                                </el-icon>
                            </div>
                        </div>
                    </div>
                    <div class="frontPageEchartMain">
                        <publicEchart v-if="publicEchartShow" :xAxis="xAxis" :series="series"></publicEchart>
                    </div>
                </div>
                <!--<div class="frontPageTitle">-->
                <!--<div class="frontPageTitleLine"></div>-->
                <!--<span>详细数据</span>-->
                <!--</div>-->
                <!--<div class="frontPageTable" style="margin-top: 10px;">-->
                <!--<publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData">-->
                <!--<el-table-column prop="date" label="时间"></el-table-column>-->
                <!--<el-table-column prop="name" label="缴费金额(元)"></el-table-column>-->
                <!--<el-table-column prop="name" label="缴费人数(人)"></el-table-column>-->
                <!--<el-table-column prop="name" label="报名人数(人)"></el-table-column>-->
                <!--<el-table-column label="操作">-->
                <!--<template #default="scope">-->
                <!--<el-link class="tableButton" type="primary" @click.prevent="exportExcel(scope.row)">-->
                <!--下载报表-->
                <!--</el-link>-->
                <!--</template>-->
                <!--</el-table-column>-->
                <!--</publicTable>-->
                <!--</div>-->
            </div>
        </div>
    </div>
</template>

<script>

    import publicEchart from '../../components/publicEchart.vue'

    import {CaretBottom, Top, Bottom} from '@element-plus/icons'

    // import publicTable from '../../components/publicTable.vue'

    import XLSX from 'xlsx';

    import {businessGet} from '../../api/business.js'

    export default {
        name: "frontPage",
        components: {
            publicEchart,
            CaretBottom,
            Top,
            Bottom,
            // publicTable
        },
        data() {
            return {
                topListData: [
                    {
                        title: '近24小时缴费金额',
                        money: '0',
                        sort: 'up',
                        baifenbi: '0'
                    },
                    {
                        title: '近24小时缴费人数',
                        money: '0',
                        sort: 'up',
                        baifenbi: '0'
                    },
                    {
                        title: '近24小时报名人数',
                        money: '0',
                        sort: 'up',
                        baifenbi: '0'
                    }
                ],
                topListDataSel: 0,
                searchHeight: 0,
                tableData: [
                    {
                        date: '11223',
                        name: '11223'
                    }
                ],
                publicEchartShow: false,
                xAxis: [],
                series: [],
                price_xAxis: [],
                price_series: [],
                count_xAxis: [],
                count_series: [],
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData() {
                let url = '/admin/v1/home';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let yesterday = res.data.data.yesterday;
                        let before_yesterday = res.data.data.before_yesterday;
                        this.topListData[0].money = yesterday.pay_price;
                        this.topListData[1].money = yesterday.pay_count;
                        this.topListData[2].money = yesterday.sign_count;
                        if (yesterday.price_day_on_day >= 0) {
                            this.topListData[0].sort = 'up'
                            this.topListData[0].baifenbi = (yesterday.price_day_on_day * 100).toFixed(2);
                        }
                        else if (yesterday.price_day_on_day < 0) {
                            this.topListData[0].sort = 'down'
                            this.topListData[0].baifenbi = (yesterday.price_day_on_day * - 1 * 100).toFixed(2);
                        }
                        else {
                            this.topListData[0].sort = 'none'
                            this.topListData[0].baifenbi = yesterday.price_day_on_day;
                        }
                        if (yesterday.count_day_on_day >= 0) {
                            this.topListData[1].sort = 'up'
                            this.topListData[1].baifenbi = (yesterday.count_day_on_day * 100).toFixed(2);
                        }
                        else if (yesterday.count_day_on_day < 0) {
                            this.topListData[1].sort = 'down'
                            this.topListData[1].baifenbi = (yesterday.count_day_on_day * - 1 * 100).toFixed(2);
                        }
                        else {
                            this.topListData[1].sort = 'none'
                            this.topListData[1].baifenbi = yesterday.count_day_on_day;
                        }
                        if (yesterday.sign_day_on_day >= 0) {
                            this.topListData[2].sort = 'up'
                            this.topListData[2].baifenbi = (yesterday.sign_day_on_day * 100).toFixed(2);
                        }
                        else if (yesterday.sign_day_on_day < 0) {
                            this.topListData[2].sort = 'down'
                            this.topListData[2].baifenbi = (yesterday.sign_day_on_day * - 1 * 100).toFixed(2);
                        }
                        else {
                            this.topListData[2].sort = 'none'
                            this.topListData[2].baifenbi = yesterday.sign_day_on_day;
                        }

                        let price_xAxis = [];
                        for (let k in yesterday.time_pay_price) {
                            price_xAxis.push(k);
                        }
                        let price_zuotian = [];
                        for (let k in yesterday.time_pay_price) {
                            price_zuotian.push(yesterday.time_pay_price[k])
                        }
                        let price_qiantian = [];
                        for (let k in before_yesterday.time_pay_price) {
                            price_qiantian.push(before_yesterday.time_pay_price[k])
                        }
                        let price_series = [
                            {
                                name: '昨天',
                                data: price_zuotian,
                                type: 'line'
                            },
                            {
                                name: '前天',
                                data: price_qiantian,
                                type: 'line'
                            },
                        ];
                        this.price_xAxis = price_xAxis;
                        this.price_series = price_series;

                        let count_xAxis = [];
                        for (let k in yesterday.time_pay_count) {
                            count_xAxis.push(k);
                        }
                        let count_zuotian = [];
                        for (let k in yesterday.time_pay_count) {
                            count_zuotian.push(yesterday.time_pay_count[k])
                        }
                        let count_qiantian = [];
                        for (let k in before_yesterday.time_pay_count) {
                            count_qiantian.push(before_yesterday.time_pay_count[k])
                        }
                        let count_series = [
                            {
                                name: '昨天',
                                data: count_zuotian,
                                type: 'line'
                            },
                            {
                                name: '前天',
                                data: count_qiantian,
                                type: 'line'
                            },
                        ];
                        this.count_xAxis = count_xAxis;
                        this.count_series = count_series;
                        this.refreshEchart();
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            exportExcel() {
                //数据表格
                var aoa = [
                    ['表格在此', '', '', ''],
                    ['', '', '', ''],
                    ['姓名', '性别', '年龄', '注册时间'],
                    ['张三', '男', 18, new Date()],
                    ['李四', '女', 22, new Date()]
                ];

                //创建book
                var wb = XLSX.utils.book_new();

                var ws = XLSX.utils.aoa_to_sheet(aoa);
                //设置列宽
                ws['!cols'] = [
                    {width: 50},
                    {width: 15},
                    {width: 15},
                    {width: 10}
                ];
                ws['!merges'] = [
                    // 设置A1-C1的单元格合并
                    {
                        s: {r: 0, c: 0},
                        e: {r: 1, c: 3}
                    }
                ];

                ws['A3'].l = {Target: "http://sheetjs.com", Tooltip: "Find us @ SheetJS.com!"};

                var aoa2 = [
                    ['姓名', '性别', '年龄', '注册时间'],
                    ['张三', '男', 18, new Date()],
                    ['李四', '女', 22, new Date()]
                ];

                var ws2 = XLSX.utils.aoa_to_sheet(aoa2);

                ws2["A1"].s = {
                    font: {sz: 14, bold: true, vertAlign: true},
                    alignment: {vertical: "center", horizontal: "center"},
                    fill: {bgColor: {rgb: "E8E8E8"}, fgColor: {rgb: "E8E8E8"}}
                };

                var aoa3 = [
                    ['姓名', '性别', '年龄', '注册时间'],
                    ['张三', '男', 18, new Date()],
                    ['李四', '女', 22, new Date()]
                ];

                var ws3 = XLSX.utils.aoa_to_sheet(aoa3);
                ws3["!margins"] = {left: 0.25, right: 0.25, top: 0.75, bottom: 0.75, header: 0.3, footer: 0.3}

                //sheet写入book
                XLSX.utils.book_append_sheet(wb, ws, "操作合并，列宽");
                XLSX.utils.book_append_sheet(wb, ws2, "正常");
                XLSX.utils.book_append_sheet(wb, ws3, "操作间隔");

                //输出

                var timestamp = (new Date()).getTime();
                XLSX.writeFile(wb, "file" + timestamp + ".csv");
            },
            selOneTab(index) {
                this.topListDataSel = index;
                this.refreshEchart();
            },
            refreshEchart() {
                this.publicEchartShow = false;
                this.$nextTick(() => {
                    setTimeout(() => {
                        if (this.topListDataSel == 0) {
                            this.xAxis = this.price_xAxis;
                            this.series = this.price_series;
                        }
                        else if (this.topListDataSel == 1) {
                            this.xAxis = this.count_xAxis;
                            this.series = this.count_series;
                        }
                        console.log(this.xAxis);
                        console.log(this.series);
                        this.publicEchartShow = true;
                    }, 500)
                })
            },
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .frontPage {
        width: 100%;
        box-sizing: border-box;
        padding-top: 24px;
        padding-left: 30px;
        padding-Right: 30px;
    }

    .frontPageIn {
        width: 100%;
        box-sizing: border-box;
    }

    .frontPageTop {
        width: 100%;
        height: 32px;
        box-sizing: border-box;
        line-height: 32px;
    }

    .frontPageTopText {
        color: rgba(65, 148, 242, 1);
        font-size: 20px;
    }

    .frontPageTopText2 {
        color: rgba(112, 112, 112, 1);
        font-size: 12px;
    }

    .frontPageEchart {
        width: 100%;
        height: 504px;
        box-sizing: border-box;
        border: 1px solid #e7eaf0;
        margin-top: 24px;
    }

    .frontPageEchartTop {
        width: 100%;
        height: 102px;
        box-sizing: border-box;
        background-color: #fafbfc;
    }

    .frontPageEchartTopOne {
        width: 33.33%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        float: left;
        position: relative;
        cursor: pointer;
    }

    .frontPageEchartTopText {
        width: 100%;
        height: 22px;
        box-sizing: border-box;
        font-size: 14px;
        color: #333;
        line-height: 22px;
        text-align: center;
    }

    .frontPageEchartTopText2 {
        width: 100%;
        height: 38px;
        box-sizing: border-box;
        font-size: 24px;
        color: #333;
        line-height: 38px;
        text-align: center;
    }

    .frontPageEchartTopText3 {
        width: 100%;
        height: 22px;
        box-sizing: border-box;
        font-size: 14px;
        color: #999;
        line-height: 22px;
        text-align: center;
    }

    .frontPageEchartTopAc > .frontPageEchartTopText {
        color: #ffffff;
    }

    .frontPageEchartTopAc > .frontPageEchartTopText2 {
        color: #ffffff;
    }

    .frontPageEchartTopAc > .frontPageEchartTopText3 {
        color: #ffffff;
    }

    .frontPageEchartTopAc .frontPageEchartTopText4 {
        color: #ffffff;
    }

    .frontPageEchartTopAc .frontPageEchartTopText5 {
        color: #ffffff;
    }

    .frontPageEchartTopText4 {
        font-size: 14px;
        color: #F15451;
    }

    .frontPageEchartTopText5 {
        font-size: 14px;
        color: #009b40;
    }

    .frontPageEchartTopBL {
        border-left: 1px solid #e7eaf0;
    }

    .frontPageEchartTopOneSel {
        font-size: 0;
        position: absolute;
        bottom: -18px;
        left: 50%;
        transform: translateX(-50%);
    }

    .frontPageEchartTopAc {
        background-color: #009b40 !important;
        color: #ffffff !important;
    }

    .frontPageEchartMain {
        width: 100%;
        height: 400px;
    }

    .frontPageTitle {
        width: 100%;
        height: 32px;
        box-sizing: border-box;
        padding-left: 10px;
        position: relative;
        color: rgba(112, 112, 112, 1);
        font-size: 16px;
        line-height: 32px;
        margin-top: 20px;
    }

    .frontPageTitleLine {
        width: 4px;
        height: 18px;
        background-color: rgba(65, 148, 242, 1);
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

</style>
